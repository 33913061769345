import React from "react";
import { Link } from "react-router-dom";

export const Card = ({ id, name, link, description }) => {
  return (
    <Link to={`/product/${id}`}>
      <div className="">
        <button className="bg-font hover:bg-button text-white text-xl font-bold py-2 px-4 rounded-t-2xl tracking-widest w-full">
          {name}
        </button>
        <div className="max-w-sm overflow-hidden shadow-lg h-full bg-white flex flex-col  items-center justify-start p-10 rounded-b-2xl">
        <img
          className="w-56 h-96"
          src={link}
          alt="Card mage"
        />
        </div>
      </div>
    </Link>
  );
};

