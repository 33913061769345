import React from "react";
import { products } from "./Data";
import { Link } from "react-router-dom";

const ThirdSection = () => {
  return (
    <div className="mx-auto mt-24 max-w-7xl px-4 sm:mt-32 sm:px-6 lg:mt-24 lg:px-8">
      <div className="mx-auto flex max-w-6xl flex-col space-y-7 text-center">
        <h3 className="text-lg font-medium uppercase tracking-wide text-font">
          Products
        </h3>

        <h2 className="text-4xl font-bold leading-tight tracking-wide text-font xl:text-5xl">
          Our Premium Automotive Products
        </h2>

        <p className="text-lg text-font text-justify">
          At Zerexx, we offer a comprehensive range of high-quality automotive
          products designed to enhance the performance and longevity of your
          vehicle. Our product line includes superior coolants like our Zerexx
          Pink Vehicle, Zerexx Green Vehicle and Zerexx Blue Vehicle ensuring
          optimal engine temperature regulation in all weather conditions.
          Additionally, our transmission fluids, gear oils, and brake fluids are
          engineered to meet the highest industry standards, providing you with
          reliable and efficient solutions for all your automotive needs.
        </p>
      </div>

      <div className="isolate mx-auto mt-14 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:mt-24 lg:max-w-none lg:grid-cols-3">
        {products.slice(0, 3).map((product) => (
          <div
            key={product.id}
            className="flex flex-col justify-between space-y-10 rounded-2xl bg-white p-4 text-center shadow-xl"
          >
            <div className="flex flex-col items-center">
              <h3 className="text-3xl font-semibold leading-8 p-4 font-passion-one  text-font">
                {product.name}
                <br />
              </h3>

              <img src={product.link1L} alt="prods" className="h-96" />
            </div>
          </div>
        ))}
      </div>
      <Link to="/products">
        <button className="bg-button w-full mt-4 p-2 rounded-xl font-passion-one text-white  text-xl">
          Browse Products{" "}
        </button>
      </Link>
    </div>
  );
};

export default ThirdSection;
