import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import Lottie from "lottie-react";
import Oil from "../components/Oil.json";

function Navbar() {
  const [nav, setNav] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 630 && nav) {
        setNav(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [nav]);

  return (
    <header className="bg-secondary p-2 shadow-lg">
      <div className="flex justify-between">
        <NavLink to="/">
          <div className="flex items-center font-semibold text-xl justify-center">
            <img src={logo} alt="logo" className="h-[80px] w-30" />
            <Lottie animationData={Oil} className="h-[70px] p-0" />
          </div>
        </NavLink>

        <div className="my-auto">
          <ul className="flex max-sm:hidden uppercase font-bold">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mr-10 p-1 border-2 border-transparent hover:text-[#a3a4a5] rounded ">
                Home
              </li>
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mr-10 p-1 border-2 border-transparent hover:text-[#a3a4a5] rounded">
                About Us
              </li>
            </NavLink>
            <NavLink
              to="/products"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mr-10 p-1 border-2 border-transparent hover:text-[#a3a4a5] rounded ">
                Our Products
              </li>
            </NavLink>

            <NavLink
              to="/contact"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mr-5 p-1 border-2 border-transparent hover:text-[#a3a4a5] rounded">
                Contact Us
              </li>
            </NavLink>
          </ul>
          <span
            className="my-auto hidden max-sm:block"
            onClick={() => setNav(!nav)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="darkblue"
              className="w-10 h-8 object-cover"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </span>
        </div>
      </div>
      {nav && (
        <div
          id="menu"
          className="mt-5 p-5  rounded-2xl flex justify-center bg-white bg-opacity-70"
          onClick={() => setNav(!nav)}
        >
          <ul className="font-bold">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mb-2 hover:text-[#a3a4a5]">Home</li>
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mb-2 ">About Us</li>
            </NavLink>
            <NavLink
              to="/products"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li className="mb-2 hover:text-[#a3a4a5]">Our Products</li>
            </NavLink>

            <NavLink
              to="/contact"
              style={({ isActive }) => ({
                color: isActive ? "#a3a4a5" : "#00316d",
                textDecoration: "none",
              })}
            >
              <li>Contact Us</li>
            </NavLink>
          </ul>
        </div>
      )}
    </header>
  );
}

export default Navbar;
