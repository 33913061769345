import { useEffect, useState } from "react";
import { Loader } from "../components/Loader";
import { products } from "../components/Data";
import { Card } from "../components/Card";
import banner from "../assets/prodbg.jpeg"

function Products() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  const img = "../assets/Banner 1.png";
  return (
    <div className="">
      {loading ? (
        <div className="flex items-center justify-center h-[880px]">
          <Loader />
        </div>
      ) : (
        <div className="text-center bg-main max-sm:h-full">
          <div className="flex flex-col items-center ">
          <div
            className="bg-cover bg-center h-96 overflow-hidden shadow-lg w-full"
            style={{ backgroundImage: `url(${banner})` }}
          >
            <div className="bg-black bg-opacity-10 p-10 pt-32 pl-48 h-full flex flex-col justify-end font-passion-one flex justify-center max-md:p-2">
              <h2 className=" bg-opacity-80 font-bold text-5xl mb-2 text-font bg-white p-12 ml-20 max-md:ml-0 max-w-lg text-center">
                Products
              </h2>
            </div>
          </div>
            <div className="flex flex-wrap justify-center gap-4 md:gap-10 mt-10 p-4">
              {products.map((product, index) => (
                <Card
                  key={index}
                  name={product.name}
                  link={product.link1L}
                  description={product.description}
                  id={product.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Products;
