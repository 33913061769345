import React from "react";
import { Link } from "react-router-dom";
import first from "../assets/1080x1080.png";

const FirstSection = () => {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="pt-8 grid grid-cols-1 items-center gap-14  lg:grid-cols-2">
        <div className="mx-auto flex max-w-3xl flex-col gap-8 ">
          <span className="text-lg font-medium text-font ">ZEREXX</span>
          <h2 className="text-4xl font-bold leading-tight tracking-wide text-font xl:text-5xl">
            Zerexx: Trusted Solutions for Your Vehicle
          </h2>

          <p className="text-lg text-font text-justify">
            Zerexx has been at the forefront of
            automotive innovation, delivering top-tier motor oils, coolants, and
            fluids. Whether it's our pioneering synthetic blend oils or
            high-performance racing oils, our products are engineered to enhance
            engine life and performance. With a legacy of firsts and a
            commitment to quality, trust Zerexx to keep your vehicle running
            smoothly.
          </p>

          <div className="flex space-x-8">
            <Link to="/about">
              <div className="rounded-md bg-button px-10 py-3 text-md font-semibold text-white shadow-sm hover:bg-opacity-90 cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-500 ">
                About Us
              </div>
            </Link>
          </div>
        </div>
        <div
          className="order-last 
mx-auto max-w-lg lg:order-first"
        >
          <img
            src={first}
            alt="mage"
            width={1080}
            height={1080}
            className="rounded-xl object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
