import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import NoPage from "./pages/NoPage";
import Contactus from "./pages/Contactus";
import Aboutus from "./pages/Aboutus";
import Products from "./pages/Products";
import LandingPage from "./pages/LandingPage";
import Footer from "./components/Footer";
import ProductDetail from "./pages/ProductDetail";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <ScrollToTop/>
        <Routes>
          <Route index element = {<LandingPage/>}/>
          <Route path="/products" element={<Products />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/product/:productId" element={<ProductDetail/>} />
          <Route path="*" element={<NoPage />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

export default App;
