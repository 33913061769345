import { useEffect, useState } from "react";
import { Loader } from "../components/Loader";

function Contactus() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="">
      {loading ? (
        <div className="flex items-center justify-center h-[880px] ">
          <Loader />
        </div>
      ) : (
        <section class="bg-main max-sm:h-full" id="contact">
          <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20 ">
            <div class="mb-4 ">
              <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12 ">
                <h2 class=" mb-4 font-bold  text-font text-5xl font-passion-one">
                  Get in touch
                </h2>
              </div>
            </div>
            <div class="flex justify-center">
              <div class="grid md:grid-cols-2">
                <div class="h-full pr-6 max-md:p-0">
                  <p class="mt-3 mb-12 text-font text-justify text-lg">
                    For any inquiries regarding our automotive products or
                    services, please don't hesitate to reach out. Whether you
                    need assistance with choosing the right coolant, have
                    questions about our product specifications, or require
                    technical support, our dedicated team is here to help.
                    Contact us today to experience unparalleled customer service
                    and expert advice for all your automotive needs.
                  </p>

                  <ul class="mb-6 md:mb-0 flex flex-col max-sm:justify-center max-sm:items-center ">
                    
                    <li class="flex mr-[62px]">
                      <div class="flex h-10 w-10 items-center justify-center rounded bg-button text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="h-6 w-6"
                        >
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                          <path d="M15 7a2 2 0 0 1 2 2"></path>
                          <path d="M15 3a6 6 0 0 1 6 6"></path>
                        </svg>
                      </div>
                      <div class="ml-4 mb-4 text-font">
                        <h3 class="mb-2 font-bold  text-2xl tracking-tight">
                          Contact
                        </h3>
                        <p>Mail: info@zerexx.com</p>
                      </div> 

                    </li>
                    <li class="flex">
                      <div class="flex h-10 w-10 items-center justify-center rounded bg-button text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="h-6 w-6"
                        >
                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                          <path d="M12 7v5l3 3"></path>
                        </svg>
                      </div>
                      <div class="ml-4 mb-4 text-font">
                        <h3 class="mb-2 font-bold text-2xl leading-6 ">
                          Working Hours
                        </h3>
                        <p>Monday - Friday: 08:30 - 18:00</p>
                        <p>Saturday: 08:30 - 13:00</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  class="card h-fit max-w-6xl p-5 md:p-12 flex flex-wrap max-sm:justify-center"
                  id="form"
                >
                  <h2 class="mb-4 text-4xl font-bold text-button">
                    Ready to Get Started?
                  </h2>
                  <form id="contactForm">
                    <div class="mb-6">
                      <div class="mx-0 mb-1 sm:mb-4">
                        <div class="mx-0 mb-1 sm:mb-4">
                          <label
                            for="name"
                            class="pb-1 text-xs uppercase tracking-wider"
                          ></label>
                          <input
                            type="text"
                            id="name"
                            autocomplete="given-name"
                            placeholder="Your name"
                            class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                            name="name"
                          />
                        </div>
                        <div class="mx-0 mb-1 sm:mb-4">
                          <label
                            for="email"
                            class="pb-1 text-xs uppercase tracking-wider"
                          ></label>
                          <input
                            type="email"
                            id="email"
                            autocomplete="email"
                            placeholder="Your email address"
                            class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                            name="email"
                          />
                        </div>
                      </div>
                      <div class="mx-0 mb-1 sm:mb-4">
                        <label
                          for="textarea"
                          class="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <textarea
                          id="textarea"
                          name="textarea"
                          cols="30"
                          rows="5"
                          placeholder="Write your message..."
                          class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                        ></textarea>
                      </div>
                    </div>
                    <div class="text-center">
                      <button
                        type="submit"
                        class="w-full bg-button text-white px-6 py-3 font-xl rounded-md sm:mb-0 font-semibold"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default Contactus;
