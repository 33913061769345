import React from "react";
import { Link } from "react-router-dom";

const SecondSection = () => {
  return (
    <div className="mx-auto mt-24 max-w-7xl px-4 sm:mt-32 sm:px-6 lg:mt-24 lg:px-8">
      <div className="flex max-w-4xl flex-col space-y-7">
        <h3 className="text-lg font-medium uppercase tracking-wide text-font">
          Contact Us
        </h3>

        <h2 className="text-4xl font-bold leading-tight tracking-wide text-font xl:text-5xl">
          Contact Us for Our Products
        </h2>

        <p className="text-lg text-font text-justify">
          For any inquiries related to automotive coolants, lubricants, or other
          vehicle maintenance solutions, feel free to reach out to us. Our team
          of experts is dedicated to providing the highest quality products and
          exceptional customer service. Whether you need help selecting the
          right motor oil, have questions about our products, or require
          technical support, we're here to assist you. Contact us today to
          ensure your vehicle runs smoothly and efficiently with our trusted
          solutions.
        </p>

        <div className="flex space-x-8">
          <Link to="/contact">
          <div className="rounded-md bg-button px-10 py-3 text-md font-semibold text-white shadow-sm hover:bg-opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-500 cursor-pointer">
            Contact Us
          </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
