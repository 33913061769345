import React from "react";
import FirstSection from "../components/FirstSection";
import SecondSection from "../components/SecondSection";
import ThirdSection from "../components/ThirdSection";
import Slider from "../components/Slider";
import FourthSection from "../components/FourthSection";
import ReviewSection from "../components/ReviewSection";

const LandingPage = () => {

  return (
    <div className="h-full pb-10 bg-main">
{/* <div className="w-full h-screen bg-cover bg-center" style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}>
      <div className="text-white text-center pt-32">
        <h1 className="text-4xl font-bold">Welcome to Our Website</h1>
        <p className="mt-4 text-lg">Explore amazing cars and more!</p>
      </div>
    </div>
      <Slider/> */}
      <Slider/>
      <FirstSection/>
      <ThirdSection/>
      <FourthSection/>
      <ReviewSection/>
      <SecondSection/>
      
    </div>
  );
};

export default LandingPage;
