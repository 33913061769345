import React, { useState, useEffect } from "react";
import { Loader } from "../components/Loader";
import aboutus from "../assets/aboutus.jpeg"
function Aboutus() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <div className="flex items-center justify-center h-[880px]">
          <Loader />
        </div>
      ) : (
        <div>
          <div
            className="bg-cover bg-center h-96 overflow-hidden shadow-lg mb-2"
            style={{ backgroundImage: `url(${aboutus})` }}
          >
            <div className="bg-black bg-opacity-10 p-10 pt-32 pl-48 h-full flex flex-col justify-end font-passion-one flex justify-center max-md:p-2">
              <h2 className=" bg-opacity-80 font-bold text-5xl mb-2 text-font bg-white p-12 ml-20 max-md:ml-0 max-w-lg text-center">
                About Us
              </h2>
            </div>
          </div>
          <div className="bg-main p-4 text-font ">
            <div className="max-w-6xl mx-auto">
              <h3 className="font-bold text-3xl tracking-tight mb-2 mt-4">
                Zerexx
              </h3>
              <p className="font-semibold text-xl mb-4">
                Inventing The Way Forward.
              </p>
              <p className="text-lg text-justify">
                Zerexx is a well-known brand in automotive and industrial
                solutions, creating future-ready products and best-in-class
                services for partners around the globe.
                <br />
                <br />
                As The Original, we’ve been innovating to solve problems. With
                sales in many countries and territories, our solutions are
                available for every engine and drivetrain, including
                high-mileage and heavy-duty vehicles. And we’re committed to
                powering the future of mobility for vehicles with electric,
                hybrid and internal combustion powertrains – today and as we
                move forward. <br />
                <br />
                Our strategic partnership creates a powerful combination to
                drive unparalleled product innovation and sustainable business
                solutions. We will continue to operate from our Thailand
                headquarters, providing the high-quality lubricants you know and
                trust.
              </p>
            </div>
            <div className="max-w-6xl mx-auto flex gap-10 mt-2 mb-4">
              <div className="flex flex-wrap  justify-around">
                <div className="w-full md:w-1/2 mt-2">
                  <h1 className="font-bold text-2xl tracking-tight mb-2 mt-4">
                    Our Mission
                  </h1>
                  <p className="text-lg">
                    To continuously invent and reinvent the best solutions to
                    global automotive and industrial challenges.
                  </p>
                </div>
                <div className="w-full md:w-1/2 mt-2">
                  <h1 className="font-bold text-2xl tracking-tight mb-2 mt-4">
                    Our Purpose
                  </h1>
                  <p className="text-lg w-full">
                    To inspire and enable what moves the world forward.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Aboutus;
