import React, { useEffect, useState } from 'react';
import banner1 from "../assets/Banner 1.png"
import banner2 from "../assets/Banenr 2.jpg"

const Slider = () => {
  const photos = [
    banner1,banner2
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [photos.length]);

  return (
    <div className="relative w-full mx-auto overflow-hidden h-[100%] max-md:h-[100%]">
      <div
        className="flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {photos.map((photo, index) => (
          <img
            key={index}
            src={photo}
            alt={`Slide ${index}`}
            className="w-full flex-shrink-0 h-[600px] max-md:object-cover max-md:h-auto"
          />
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
  <button
    className="bg-black bg-opacity-60 text-white w-10 h-10 rounded-full focus:outline-none max-md:top-30"
    onClick={() => setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length)}
    style={{ position: 'absolute', left: '10px' }}
  >
    &lt;
  </button>
  <button
    className="bg-black bg-opacity-60 text-white w-10 h-10 rounded-full focus:outline-none max-md:top-30"
    onClick={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length)}
    style={{ position: 'absolute', right: '10px' }}
  >
    &gt;
  </button>
</div>

    </div>
  );
};

export default Slider;
