import React from "react";
import { products } from "./Data";
import { Link } from "react-router-dom";
import rev1 from "../assets/rev1.png";
import rev2 from "../assets/rev2.jpeg";
import rev3 from "../assets/rev3.jpeg";
import stars from "../assets/stars.png";

const ReviewSection = () => {
  return (
    <div>
      <div className="mx-auto max-w-7xl px-4  sm:px-6  lg:px-8 mt-20">
        <div className="flex justify-around max-md:flex-col max-md:gap-10 max-md:items-center">
          <div className="flex flex-col justify-between text-center items-center  w-32">
            <img src={rev1} alt="rev1" className="rounded-full" />
            <img src={stars} alt="rev1" className="rounded-full" />
            <p className="w-52 italic h-[200px]">
              {" "}
              With a range of options available, including prediluted
              ready-to-use coolants and concentrated formulas, Zerexx caters to
              various vehicle types and requirements.
            </p>
          </div>
          <div className="flex flex-col justify-between text-center items-center  w-32">
            <img src={rev2} alt="rev1" className="rounded-full" />

            <img src={stars} alt="rev1" className="rounded-full" />
            <p className="w-52 italic h-[200px]">
              {" "}
              Users appreciate the brand's commitment to corrosion prevention
              and heat transfer. Zerexx ensures that your vehicle's cooling
              system remains efficient, regardless of the weather conditions.
            </p>
          </div>
          <div className="flex flex-col justify-between text-center items-center  w-32">
            <img src={rev3} alt="rev1" className="rounded-full" />
            <img src={stars} alt="rev1" className="rounded-full" />
            <p className="w-52 italic h-[200px]">
              Zerexx products have gained a reputation for their reliable
              performance and effective engine protection. Whether you're
              driving a compact car, an SUV, or a heavy-duty truck.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;
