import React from "react";
import { products } from "./Data";
import { Link } from "react-router-dom";

const FourthSection = () => {
  return (
    <div className="mx-auto mt-24 max-w-7xl px-4 sm:mt-32 sm:px-6 lg:mt-24 lg:px-8">
      <div className="isolate mx-auto mt-14 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:mt-24 lg:max-w-none lg:grid-cols-3">
        {products.slice(0, 3).map((product) => (
          <div
            key={product.id}
            className="flex flex-col justify-between space-y-10 rounded-2xl bg-white p-4 text-center shadow-xl text-font"
          >
            <p className="font-bold text-4xl text-start">{product.usage}</p>
            <div className="flex flex-col items-center h-[500px]">
              

              <img src={product.linkUsage} alt="prods" className="h-84 " />
              <p className="mt-3 text-justify">{product.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FourthSection;
