import blueone from "../assets/blueone.png";
import greenone from "../assets/green1.png";
import pinkone from "../assets/pink1.png";
import bluefour from "../assets/blue4.png";
import greenfour from "../assets/green4.png";
import pinkfour from "../assets/pink4.png";
import antifreeze from "../assets/antifreeze.jpeg";
import choosing from "../assets/choosing.jpeg";
import tips from "../assets/tips.jpeg";

export const products = [
  {
    id: "1",
    name: "Zerexx Pink Vehicle",
    description:
      "Antifreeze, or engine coolant, is a colored liquid that is mixed with water to help regulate your engine during extreme temperatures. As the temperature outside changes from hot to cold coolant is pumped throughout the engine block to maintain an even operating temperature. Antifreeze does more than just regulate temperature, however. Maintaining proper fluid levels also helps to prevent corrosion.",
    long_description:
      "The Zerexx Pink Vehicle is a premium solution specifically engineered for all manufactured vehicles. This antifreeze/coolant excels in maintaining the engine's temperature by ensuring superior heat transfer, which is crucial in preventing overheating in hot weather and freezing in cold climates. Its unique formula includes high-quality corrosion inhibitors that safeguard your engine components, prolonging their lifespan and ensuring smooth, efficient operation. Whether you are driving in the heat of summer or the cold of winter, this product delivers consistent performance, protecting your vehicle's engine and keeping it in peak condition year-round.",
    specs: [
      "Advanced heat transfer capabilities",
      "Corrosion protection for extended engine life",
      "Suitable for summer and winter conditions",
      "Designed for all manufactured vehicles",
    ],
    link1L: pinkone,
    link4L: pinkfour,
    usage: "What is Antifreeze?",
    linkUsage:antifreeze
  },
  {
    id: "2",
    name: "Zerexx Green Vehicle",
    price: "2500$",
    description:
      "Engine coolant, also known as antifreeze, is mixed with water to keep the radiator from freezing in extreme cold and overheating in extreme heat. There are many different types of coolant, so it’s important to know what variety is right for your car or truck.",
    long_description:
      "The Zerexx Green Vehicle is the ideal choice for vintage and classic car owners who require the proven reliability of inorganic additive technology (IAT). This antifreeze/coolant offers excellent protection against rust and corrosion, which are common issues in older cooling systems. By using this product, you can ensure that your vehicle's cooling system remains efficient and effective, preserving the integrity and performance of your prized classic car. Its dependable formulation is trusted by enthusiasts who seek to maintain their vehicles in optimal condition.",
    specs: [
      "Inorganic additive technology (IAT)",
      "Protection against rust and corrosion",
      "Ideal for older vehicles",
      "Maintains cooling system efficiency",
    ],
    link1L: greenone,
    link4L: greenfour,
    usage: "Choosing the Right Engine Coolant.",
    linkUsage:choosing
  },
  {
    id: "3",
    name: "Zerexx Blue Vehicle",
    price: "3000$",
    description:
      "Tip 1: Choose The Right Coolant For Your Car Tip 2: Monitor Seasonally Tip 3: Add/flush And Replace Coolant Safely Tip 4: How To Properly Dispose Of Coolant",
    long_description:
      "The Zerexx Blue Vehicle is tailored to meet the demanding needs of all cars and trucks. Its cutting-edge, long-life formula is designed to offer unparalleled protection against extreme temperatures, preventing both overheating in hot weather and freezing in cold conditions. This antifreeze/coolant also includes robust corrosion inhibitors that protect your engine from rust and deterioration, ensuring longevity and reliability. By using this product, you can be confident that your vehicle's cooling system will perform at its best, maintaining optimal engine health and extending the lifespan of your vehicle.",
    specs: [
      "Formulated for all vehicles",
      "Advanced, long-life formula",
      "Protection against temperature extremes",
      "Rust and corrosion inhibitors",
    ],
    link1L: blueone,
    link4L: bluefour,
    usage: "Coolants Maintenance Tips:",
    linkUsage:tips
  },
];