import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { products } from "../components/Data";

const ProductDetail = () => {
  const { productId } = useParams();
  const product = products.find((p) => p.id === productId);
  const [selectedOption, setSelectedOption] = useState('4L');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="p-10 bg-main h-full text-font max-md:p-5">
      <div className="max-w-4xl max-md:p-5 mx-auto bg-white rounded-xl shadow-lg p-6 flex flex-col md:flex-row">
        <div className="md:w-1/2 mb-4 md:mb-0 flex items-center flex-col justify-center">
          <img
            src={selectedOption === '1L' ? product.link1L : product.link4L}
            alt={product.name}
            className="h-[500px] rounded-xl"
          />
          <div className="flex space-x-2 mt-8">
            <button
              onClick={() => handleOptionClick('1L')}
              className={`px-4 py-2 rounded ${selectedOption === '1L' ? 'bg-font text-white' : 'bg-gray-200 text-black'}`}
            >
              1 liter
            </button>
            <button
              onClick={() => handleOptionClick('4L')}
              className={`px-4 py-2 rounded ${selectedOption === '4L' ? 'bg-font text-white' : 'bg-gray-200 text-black'}`}
            >
              4 liters
            </button>
          </div>
        </div>
        <div className="md:w-1/2 md:pl-6 flex flex-col justify-center">
          <h2 className="text-3xl font-bold mb-4 flex items-center justify-between">
            <p>{product.name}</p>
          </h2>
          <h2 className="text-lg font-bold mt-4 border-t border-font pt-2">
            Description:
          </h2>
          <p className="text-lg text-justify">{product.long_description}</p>
          <ul>
            <h2 className="text-lg font-bold mt-4 border-t border-font pt-2">
              Specifications:
            </h2>
            {product.specs.map((spec, index) => (
              <li key={index} className="leading-8 text-lg">
                • {spec}.
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
